import { graphql } from "gatsby"
import React from "react"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import { PageProps, SeriesContext } from "../types"
import { Stack } from "../layout/stack"
import { MarkdownCard } from "../assemblies/card"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { SecondaryNav } from "../assemblies/nav"
import { SeriesText } from "../elements/motifs"
import { expandFrontmatter } from "../util"

const SeriesTemplate: React.FC<PageProps<
  GatsbyTypes.SeriesPageQuery,
  SeriesContext
>> = ({
  data: { markdownRemark, allMarkdownRemark },
  pageContext: { parents, children, info },
}) => {
  const { title, path, thumbnail } = expandFrontmatter(
    markdownRemark?.frontmatter
  )
  const excerpt = markdownRemark?.excerpt
  const html = markdownRemark?.html || "html error"
  return (
    <PageContainer>
      <PageOuterSidebar>
        <PageSidebar>
          <SecondaryNav
            parents={parents || []}
            activePath={path}
            siblings={[{ title, path }]}
            navChildren={children}
          />
        </PageSidebar>
        <PageMain>
          <PageHeader>
            <PageTitle title={title} />
            <PageMeta
              description={excerpt}
              thumbnail={thumbnail?.childImageSharp?.fixed?.src}
            />
            <SeriesText seriesInfo={info} />
          </PageHeader>
          <Stack
            as="article"
            gap={0}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {allMarkdownRemark.edges.length > 0 ? (
            <Stack gap={1}>
              {allMarkdownRemark.edges.map(({ node }) => (
                <MarkdownCard remark={node} key={node.id} />
              ))}
            </Stack>
          ) : null}
        </PageMain>
      </PageOuterSidebar>
      <PageFooter />
    </PageContainer>
  )
}

export default SeriesTemplate

export const query = graphql`
  query SeriesPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...MarkdownNode
      html
    }
    allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: $path } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...MarkdownNode
        }
      }
    }
  }
`
